import './App.scss';
import { useState, useEffect } from "react";

function App() {

  const [newTodo, setNewTodo] = useState('')
  const [todos, setTodos] = useState([])

  useEffect(() => {
    if( 'todos' in localStorage ){
      const newArr = JSON.parse(window.localStorage.getItem('todos') )
      setTodos(newArr)
    }
  }, [])

  const submitTodo = e => {
    e.preventDefault()
    const newArr = [...todos, { "todo": newTodo, "completed": false, "id": new Date().getTime() }]
    setTodos(newArr)
    setNewTodo('')
    window.localStorage.setItem('todos', JSON.stringify(newArr))
  }


  const checkOff = (id) => {
    const theIndex = todos.findIndex(todo => todo.id === id)
    const newArr = [...todos]

    newArr[theIndex].completed = newArr[theIndex].completed === false ? true : false

    setTodos(newArr)
    window.localStorage.setItem('todos', JSON.stringify(newArr))
  }

  const removeTodo = id => {
    const newArr = todos.filter(todo => todo.id !== id)
    setTodos(newArr)
    window.localStorage.setItem('todos', JSON.stringify(newArr))
  }


  
  
  return (
    <div className="App">
      
      <div className="body-wrap">
        <header>
          <h1>SPENSER'S <br/> TODOS</h1>
        </header>
        <main>
          <div className="wrap">
            <div className="add-contain">
              <form onSubmit={submitTodo}>
                <div className="form-row">
                  <input type="text" className="new-todo-input" placeholder="+New Todo" value={newTodo} onChange={ e => setNewTodo(e.target.value) } />


                  <button type="submit" 
                  className={`submit-btn ${newTodo.length !== 0 ? 'reveal' : 'hide'}`}
                  disabled={newTodo.length === 0}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
                      <g id="Group_1" data-name="Group 1" transform="translate(-105.5 -84)">
                        <rect id="Rectangle_1" data-name="Rectangle 1" width="5" height="24" transform="translate(115 84)" fill="#010203"/>
                        <rect id="Rectangle_2" data-name="Rectangle 2" width="5" height="24" transform="translate(129.5 93.5) rotate(90)" fill="#010203"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </form>
            </div>

            <div className="todos-contain">
              <div className="title-wrap">
                <h2>TODOS</h2>

                <p className="progress">{ todos.filter(todo => todo.completed === true).length }/{ todos.length }</p>
              </div>
              <ul className="list">
                {todos.map((todo) => (
                  <li key={todo.id} className={`todo-list-item ${todo.completed === true ? 'completed' : ''}`}>


                    <div className="btns">
                      <button className="btn check-btn" onClick={ (e) => checkOff(todo.id) }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 37.779 29.183">
                          <path id="Path_1" data-name="Path 1" d="M737.769,514l10.364,10.364,23.879-23.879" transform="translate(-736.002 -498.715)" fill="none" stroke="#010203" strokeWidth="5"/>
                        </svg>
                      </button>
                    </div>

                    <span className="todo"><span className="crossout">{ todo.todo }</span></span>

                    <button className="btn remove-btn" onClick={() => removeTodo(todo.id)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20.506 20.506">
                      <g id="Group_1" data-name="Group 1" transform="translate(-4.95 -140.714) rotate(45)">
                        <rect id="Rectangle_1" data-name="Rectangle 1" width="5" height="24" transform="translate(115 84)" className="fill"/>
                        <rect id="Rectangle_2" data-name="Rectangle 2" width="5" height="24" transform="translate(129.5 93.5) rotate(90)" className="fill"/>
                      </g>
                    </svg>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            
          </div>
        </main>
      </div>
      
    </div>
  );
}

export default App;
